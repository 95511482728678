import {
  FolderCopy,
  SdStorage as SdStorageIcon,
  Upload as UploadIcon,
} from '@mui/icons-material';
import { Box, Button, Grid, styled, Tooltip, Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface FilesLabels {
  current: number;
  max: number;
}

interface FileInputButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: any;
  handleFileChange: (event: ChangeEvent<unknown>) => void;
  allowedTypes?: string;
  disabled?: boolean;
  multiple?: boolean;
  fileSizeLabels?: FilesLabels;
  fileCountLabels?: FilesLabels;
}

const FileInputButton: FC<FileInputButtonProps> = ({
  inputRef,
  handleFileChange,
  allowedTypes = undefined,
  disabled = false,
  multiple = false,
  fileSizeLabels = undefined,
  fileCountLabels = undefined,
}) => {
  return (
    <Grid item container>
      <Button
        component="label"
        variant="outlined"
        sx={{ minHeight: 60, width: '100%' }}
        startIcon={<UploadIcon />}
        disabled={disabled}
      >
        Adjuntar
        <VisuallyHiddenInput
          type="file"
          ref={inputRef}
          multiple={multiple}
          accept={allowedTypes}
          onChange={handleFileChange}
        />
      </Button>
      <Grid
        item
        container
        justifyContent="space-between"
        sx={{ pt: 1, fontSize: 12 }}
      >
        {Boolean(fileCountLabels) && (
          <Grid item xs={6}>
            <Tooltip title="Cantidad de archivos adjuntos">
              <Box display="flex" alignItems="center">
                <FolderCopy
                  color="primary"
                  sx={{ mr: 1, fontSize: 'inherit' }}
                />
                <Typography fontSize="inherit">
                  {`${fileCountLabels?.current ?? 0}/${fileCountLabels?.max ?? 0}`}{' '}
                  archivos
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
        )}
        {Boolean(fileSizeLabels) && (
          <Grid item xs={6}>
            <Tooltip
              title={`Tamaño de${multiple ? ' los archivos adjuntos' : 'l archivo adjunto'} `}
            >
              <Box display="flex" alignItems="center">
                <SdStorageIcon
                  color="primary"
                  sx={{ mr: 1, fontSize: 'inherit' }}
                />
                <Typography fontSize="inherit">
                  {`${fileSizeLabels?.current!.toFixed(1)}/${fileSizeLabels?.max}`}{' '}
                  MB
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FileInputButton;
